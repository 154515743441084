<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :beforeList="beforeList"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <v-button text="新增" @click="handleAdd"></v-button>
        <!-- <v-button text="导出"></v-button> -->
      </template>
      <template #searchSlot>
        <community-select v-model="searchParam.communityId" label="选择园区" />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          @click="handleEdit(scope.row)"
        ></v-button>
        <v-button
          text="删除"
          type="text"
          @click="handleDelete(scope.row)"
        ></v-button>
      </template>
    </v-list>

    <el-dialog
      :close-on-click-modal="false"
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form label-position="right" label-width="100px">
        <!-- <el-form-item label="所属项目">
          <community-select
            :disabled="editStatus === 'update'"
            v-model="temp.communityId"
            label="选择园区"
          />
        </el-form-item> -->
        <el-form-item label="所属分类">
          <v-select :options="mapTypeList" v-model="temp.categoryId" />
        </el-form-item>
        <el-form-item label="类型名称">
          <v-input clearable v-model="temp.typeName"></v-input>
        </el-form-item>
        <el-form-item label="显示顺序">
          <v-input v-model="temp.typeOrder" type="number"></v-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button
          size="medium"
          @click="dialogFormVisible = false"
          text="取消"
        ></v-button>
        <v-button
          size="medium"
          type="primary"
          @click="saveEdit"
          text="确定"
        ></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getOption, createImgVNode } from "@/utils/utils.js";
import { editTitleMap } from "./map.js";
import {
  getPlaceTypeListPagingUrl,
  delPlaceTypeUrl,
  editPlaceTypeUrl,
  getPlaceTypeUrl,
} from "./api";
import { Message, MessageBox } from "element-ui";

export default {
  name: "placeTypeList",
  data() {
    let _this = this;
    return {
      mapTypeList: [],
      // searchParam: {  categoryId: "" },
      searchParam: {},
      communitList: [],
      tableUrl: getPlaceTypeListPagingUrl,
      headers: [
        {
          prop: "categoryId",
          label: "所属分类",
          formatter: (row, prop) => {
            for (var i = 0; i <= _this.mapTypeList.length - 1; i++) {
              if (_this.mapTypeList[i].value == row.categoryId) {
                return _this.mapTypeList[i].label;
              }
            }
          },
        },
        // {
        //   prop: "communityName",
        //   label: "所属项目",
        // },
        {
          prop: "typeName",
          label: "类型名称",
        },
        {
          prop: "typeOrder",
          label: "排序",
        },
        {
          prop: "insertTime",
          label: "创建时间",
        },
        {
          prop: "updateTime",
          label: "更新时间",
        },
      ],

      editStatus: null,
      dialogTitle: null,

      dialogFormVisible: false,
      temp: {
        id: null,
        categoryId: "",
        // communityId: null,
        typeName: null,
        typeOrder: 0,
      },
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    beforeList() {
      this.getKindsList();
    },
    getKindsList() {
      let params = {};
      this.mapTypeList = [];
      this.$axios.get(`${getPlaceTypeUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.label = item.categoryName;
            item.value = item.id;
            this.mapTypeList.push(item);
          });
        } else {
        }
      });
    },
    handleAdd() {
      this.editStatus = "create";
      this.dialogTitle = editTitleMap.create;
      this.dialogFormVisible = true;
      this.temp.id = null;
      this.temp.categoryId = null;
      // this.temp.communityId = this.searchParam.communityId;
      this.temp.typeName = null;
      this.temp.typeOrder = 0;
    },
    handleEdit(data) {
      this.editStatus = "update";
      this.dialogTitle = editTitleMap.update;
      this.dialogFormVisible = true;
      this.temp.id = data.id;
      this.temp.categoryId = data.categoryId;
      // this.temp.communityId = data.communityId;
      this.temp.typeName = data.typeName;
      this.temp.typeOrder = data.typeOrder;
    },
    handleDelete(data) {
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: data.id,
          };
          this.$axios
            .post(`${delPlaceTypeUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    saveEdit() {
      // if (!this.temp.communityId || this.temp.communityId == "") {
      //   this.$message.error("请选择所属园区");
      //   return;
      // }
      if (!this.temp.categoryId || this.temp.categoryId == "") {
        this.$message.error("请选择分类");
        return;
      }
      if (!this.temp.typeName || this.temp.typeName == "") {
        this.$message.error("请输入类型名称");
        return;
      }
      this.$axios.post(`${editPlaceTypeUrl}`, this.temp).then((res) => {
        if (res.code === 200) {
          Message.success("操作成功！");
          this.$refs.list.search();
        } else {
        }
      });

      this.dialogFormVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
